@import "line-awesome.min.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('~dropzone/dist/dropzone.css');
// @import "swiper/components/pagination/pagination.less";
@import './items.less';
@import './reservation.less';

.device-ios {
  height: 100vh;
}

:root {
  --f7-theme-color: #00abeb;
  --f7-secondary-color: #f3f4f6;
  --f7-checkbox-active-color: #00abeb;
  --f7-theme-color-rgb: 6, 15, 96;
  --f7-theme-color-shade: #060F60;
  --f7-theme-color-tint: #060F60;
  --f7-page-bg-color: white;
  --f7-tabbar-icon-size: 20px;
  --f7-bars-translucent-opacity: 1;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
}

.ios {
  --f7-font-family: Noto Sans, -apple-system, SF Pro Text, SF UI Text, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, sans-serif, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.page {
  background-color: white;
}

.ch-bg {
  .page-content {
    background-color: #EDEDED;
  }
}

.navbar {
  box-shadow: 0px 0px 30px 0px #0000000d;
}

.popup {
  z-index: 10600;
}

.ios-translucent-bars {
  // .navbar-bg {
  //   background-color: white;
  // }

  .toolbar {
    background-color: white;
  }
}

.device-android {
  --f7-page-transition-duration: 250ms;
}

.ios.device-android {
  .page-next {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 56px, 0);
  }

  // Animations
  .router-transition-forward {
    .page-next {
      animation: md-page-next-to-current var(--f7-page-transition-duration) forwards;
    }

    .page-current {
      animation: none;
    }
  }

  .router-transition-backward {
    .page-current {
      animation: md-page-current-to-next var(--f7-page-transition-duration) forwards;
    }

    .page-previous {
      animation: none;
      transform: none;
    }
  }
}

@keyframes md-page-next-to-current {
  from {
    transform: translate3d(0, 56px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

@keyframes md-page-current-to-next {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 56px, 0);
    opacity: 0;
  }
}

.grid-list-item {
  .item-link {
    width: 100%;

    .item-content {
      padding: 0;
      display: inline;
    }
  }

  .item-media {
    padding-bottom: 0;

    & + .item-inner {
      width: 10rem;
      margin: auto;
      padding-right: 0 !important;

      .item-title-row {
        padding: 0;
      }

      .item-title-row::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }
  }
}

.bg-trans {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01));
}

.initial-bottom.bottom {
  bottom: var(--f7-tabbar-labels-height);
  @media (min-width: 640px) {
    bottom: 12px;
    background-color: #FFFFFF;
    max-width: 45rem;
    left: 50%;
    transform: translate(-50%, 0)
  }
}

.list-border-none {
  ul:before {
    content: none;
  }

  ul:after {
    content: none;
  }

  .card-header:after {
    content: none;
  }
}

.tabbar i.icon, .tabbar-labels {
  .tabbar-label {
    font-size: 10px;
  }

  .tab-link {
    justify-content: space-evenly;
  }

  i {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    height: 60%;
    width: 60%;
  }

  i.bus-home {
    background-image: url('./../images/home_icon_off.png')
  }

  i.bus-calendar {
    background-image: url('./../images/search_icon_off.png')
  }

  i.bus-reservation {
    background-image: url('./../images/reservation_icon_off.png')
  }

  i.bus-chatting {
    background-image: url('./../images/chatting_icon_off.png');
  }

  i.bus-user {
    background-image: url('./../images/my_icon_off.png')
  }

  .tab-link-active {
    .tabbar-label {
      color: #2f3037;
    }

    i.bus-home {
      background-image: url('./../images/home_solid.png')
    }

    i.bus-calendar {
      background-image: url('./../images/bus_simple_solid.png')
    }

    i.bus-reservation {
      background-image: url('./../images/calc_solid.png')
    }

    i.bus-chatting {
      background-image: url('./../images/chat_solid.png');
    }

    i.bus-user {
      background-image: url('./../images/user_solid.png')
    }
  }
}

.registration-form {
  .list {
    margin: 0;

    .form-header {
      padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left) - var(--menu-list-offset));
    }

    ul:before {
      content: none
    }

    ul:after {
      content: none
    }

    .item-inner:after {
      content: none
    }

    .item-inner {
      padding-top: 0;
      padding-bottom: 0;
    }

    li {
      .item-link {
        .item-content {
          .item-inner {
            .item-title {
              font-weight: bold;
            }
          }
        }
      }
    }

    // .item-title {
    //   font-weight: bold;
    //   font-size: 1.1rem;
    // }

    .item-input {
      margin-bottom: 30px;

      .item-label {
        left: 0;
        font-weight: 500;
        font-style: normal;
        font-size: 12px;
        color: #979aa2;
      }

      .item-input-wrap {
        input {
          font-size: 16px;
          color: #2f3037;
          font-weight: 500;
          border-bottom: 1px solid #e6eaed;
        }

        .item-input-error-message {
          margin-top: 0;
        }
      }
    }


    ul {
      li.grid.grid-cols-12 {
        .col-span-3.my-auto {
          position: absolute;
          top: 15px;
          right: 0;

          .button {
            height: calc(var(--f7-input-height) - 4px);
            background-color: var(--f7-theme-color);
            color: white;
          }

          .disabled {
            background-color: #b9c0c8;
            color: white;
          }
        }
      }
    }
  }
}

label.item-checkbox {
  input[type='checkbox']:checked ~ .icon-checkbox {
    border-color: var(--f7-checkbox-active-color, var(--f7-secondary-color));
    background-color: var(--f7-checkbox-active-color, var(--f7-secondary-color));
  }
}

.tabbar {
  .tab-link-active {
    color: var(--f7-secondary-color);
  }
}

.tabbar-labels {
  .tab-link-active {
    color: var(--f7-secondary-color);
  }
}

.terms {
  .list {
    padding: 0;

    .item-content {
      padding: 0;
    }

    i {
      margin: 0;
    }
  }
}

.driver-card {
  .item-title {
    font-size: 14px;
  }
}

.home-recommend {
  .list {
    margin: 1rem 0;

    .item-title {
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .list ul:before {
    content: none;
  }

  .list ul:after {
    content: none;
  }
}

@media (min-width: 640px) {
  .ptr-preloader {
    z-index: -1;
  }

  .page-content {
    background-color: #FFFFFF;
  }

  .page-content > * {
    background-color: #FFFFFF;
    max-width: 45rem;
    margin: 0 auto;
  }

  .popup-tablet-fullscreen {
    max-width: 100% !important;
  }
}

.drivable-region .item-title {
  font-weight: normal !important;
  font-size: 16px !important;
}

.dialog-title {
  //display: none;
}

.home-search-btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;

  .searchbar-icon:after {
    color: white;
    font-size: 24px;
  }
}

.search-input {
  height: 45px;
  border-radius: 8px;
  line-height: 45px;
  width: 100%;
  border: 1px solid #e6eaed;
  color: #2f3037;
  background-color: white;
  font-size: 13px;

  &.place-search {
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 8px;
    background-image: url('./../images/map.png');
    padding-left: 34px;
  }

  &.disabled {
    background-color: #f3f4f6;
    color: #a3a8af
  }

  & + & {
    margin-left: 4px;
  }
}

.timepicker-input .item-input-wrap {
  width: 100%
}

.aspect-w-1.aspect-h-1 img {
  object-fit: cover;
}

.gray-border {
  border-bottom: 1px solid var(--gray-200);
}

.intro-center {
  top: 50%;
  position: absolute;
  transform: translateY(-55%);
}

.navbar.no-shadow {
  box-shadow: none;
}

.home-page .navbar {
  .navbar-bg {
    background-color: #f3f4f6;
  }
}

.bg-gray {
  background-color: #f3f4f6 !important;
}

.navbar-inner {
  padding-left: 16px;
  padding-right: 16px;
}

.home-button {
  height: 50px;
  width: 100%;
  border-radius: 7px;
  background-color: #00abeb;
  font-size: 16px;
  color: white;
  font-weight: bold;
}

.driver-item-tags div {
  background-color: #f3f4f6;
  border-radius: 11px;
  padding: 6px 11px;
  font-size: 11px;
}

.driver-show-page {
  .tabbar {
    color: #676a71;
    border-bottom: none;
    border-top-color: #bbc2c9;

    .toolbar-inner {
      padding: 0;
    }

    .tab-link-active {
      color: #2f3037;
      border-bottom: #00abeb 2px solid;
    }
  }
}

.driver-card {
  margin-bottom: 16px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  .driver-card-title {
    color: #2f3037;
    font-size: 18px;
    font-weight: bold;
  }

  .driver-card-subtitle {
    font-size: 13px;
    color: #676a71;
  }

  .driver-card-body {
    color: #676a71;

    ul li {
      font-size: 11px;
    }


    .driver-people {
      display: flex;
      font-size: 13px;
      line-height: 35px;

      input {
        border-radius: 8px;
        border: #e6eaed 1px solid;
        width: 70px;
        height: 35px;
        font-size: 15px;
        font-weight: bold;
        color: #2f3037;
        text-align: center;
        margin-right: 8px;
      }
    }

    .item-content {
      background-color: #f2f3f5;
      border-radius: 8px;
    }

    .tag {
      border: #a3a8af 1px solid;
      color: #a3a8af;
      border-radius: 13px;
      width: 55px;
      text-align: center;
      padding: 2px;

      &.active {
        border-color: #00abeb;
        color: #00abeb;
      }
    }
  }

  .driver-card-total-price {
    font-size: 18px;

    span {
      font-size: 20px;
      font-weight: bolder;
    }
  }

  .driver-card-total-sub {
    color: #676a71;
    font-size: 12px;
    text-align: right;
  }

  &.schedule {
    .driver-card-title {
      display: flex;
      color: #00abeb;
      font-size: 14px;
      align-items: center;
      font-weight: normal;
    }

    .item-content {
      padding: 0;
      min-height: auto;

      .item-inner {
        min-height: auto;
        padding: 0;

        &:before {
          right: 0;
        }
      }
    }

    .driver-card-body {
      .schedule-grid {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        div:first-child {
          min-width: 25%;
        }
      }
    }
  }
}

.left a.icon-only {
  justify-content: left !important;
}

.right a.icon-only {
  justify-content: right !important;
}

i.icon.menu_bars {
  background-image: url('./../images/menu.png');
  width: 20px;
  height: 20px;
}

i.icon.noti_bell {
  background-image: url('./../images/bell_solid.png');
  width: 20px;
  height: 20px;
}

.driver-show-page .page-content {
  padding-top: 0
}

.icon.icon-back {
  color: #000000;
}


.agree-check-box.list {
  ul {
    background-color: #E5E7EB;
  }
}

.no-toolbar {
  .page-content {
    padding-bottom: 0;
  }
}

.terms-tab {
  .toolbar-inner {
    padding: 0;
  }

  .tab-link-active {
    color: black;
    font-weight: 500;
    border-bottom: 2.5px solid var(--f7-theme-color);
  }
}


.panel.panel-left {
  .navbar-bg {
    border-top-right-radius: 1rem;
  }
}

.list .item-link.active-state {
  background-color: unset;
}

.input-clear-button:after {
  font-family: unset;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  content: '' !important;
  background-image: url('./../images/time.png')
}

.mypage-navbar {
  .navbar-bg {
    background-color: #00abeb;
  }
}

.mypage {
  .links-list a {
    height: 54px;
  }
}

.placeholder-text-white {
  input::placeholder {
    color: white
  }
}

.schedule-closed {
  display: block;
}

.accordion-item-opened .schedule-closed {
  display: none;
}
