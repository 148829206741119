.driver-reservation-card {
  .item-link {
    .item-content {
      padding-left: 0;
    }
  }
}

.driver-reservation-index { 
  .toolbar-inner {
    padding: 0;
    .tab-link-active {
      border-bottom: 1px solid black;
      font-weight: bold;
      color: black;
    }
  }
}

.normal-reservation-card{
  .card-header:after{
    content: none;
  }
  .card-footer:before{
    content: none;
  }
}