:root {
  --white: #fff;
  --black: #000;
  --primary: #00abeb;
  --secondary: #4C62A8;
  --third: #00abeb;
  --warning: #ffbf00;
  --error: #fa5b4a;
  --gray-50: #f8f9fa;
  --gray-60: #f5f6f7;
  --gray-80: #f1f3f5;
  --gray-100: #e9ecef;
  --gray-200: #dee2e6;
  --gray-300: #ced4da;
  --gray-400: #adb5bd;
  --gray-500: #848c94;
  --gray-600: #666d75;
  --gray-700: #495056;
  --gray-800: #343a40;
  --gray-900: #212529;
  --blue-50: #f5fbff;
  --blue-80: #e7f4fd;
  --blue-100: #cbe7fd;
  --blue-200: #a7d4f9;
  --blue-300: #79bef5;
  --blue-400: #51abf3;
  --blue-500: #2b96ed;
  --blue-600: #1583db;
  --blue-700: #0a70c2;
  --blue-800: #025ba5;
  --blue-900: #01457d;
  --green-50: #e6f8f3;
  --green-80: #d7f6ee;
  --green-100: #94e7d1;
  --green-200: #71d2b8;
  --green-300: #58caab;
  --green-400: #33b893;
  --green-500: #27ab86;
  --green-600: #1c9674;
  --green-700: #137b5e;
  --green-800: #10634c;
  --green-900: #0a4534;
  --yellow-50: #fff8e1;
  --yellow-80: #fff2c6;
  --yellow-100: #ffe182;
  --yellow-200: #ffd74e;
  --yellow-300: #ffc929;
  --yellow-400: #ffbf00;
  --yellow-500: #ffad01;
  --yellow-600: #ff9a00;
  --yellow-700: #f78000;
  --yellow-800: #db5600;
  --yellow-900: #b94100;
  --red-50: #fbf1ef;
  --red-80: #ffd4cc;
  --red-100: #ffaea1;
  --red-200: #ff8d7c;
  --red-300: #fe6a54;
  --red-400: #fa5b4a;
  --red-500: #ec4937;
  --red-600: #dd3c2a;
  --red-700: #c83323;
  --red-800: #aa2a1b;
  --red-900: #8d2115;
  --selected-array: rgba(0,171,235, 0.15);
  --selected-node: var(--primary);
}

.DayPicker_transitionContainer__verticalScrollable::-webkit-scrollbar {
  display: none;
}

.DayPicker__hidden {
  visibility: visible;
}

.CalendarMonth_caption__verticalScrollable {
  padding-bottom: 0;
}

.DayPicker_transitionContainer__verticalScrollable {
  background-color: #f3f4f6;
}

.CalendarMonth_table {
  width: 100% !important;
  border-collapse: separate;
  border-spacing: 0 15px !important;
}

.CalendarMonthGrid {
  width: 100% !important;
}

.CalendarMonth {
  padding: 0 !important;
}

.CalendarMonth_caption {
  text-align: left;
  padding-left: 16px;
  font-size: 13px;
  color: #2f3037;
  background-color: #f3f4f6;
}


.CalendarMonth_table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #f3f4f6;
}

// 달력 각 칸의 기본 스타일.
.CalendarDay__default {
  color: black;
  border: none;
  vertical-align: middle;
  outline: none;
  background-color: #f3f4f6;
}

// 달력 각 칸에 호버가 되었을 때 스타일
.CalendarDay__default:hover {
  background: transparent;
  border: none;
  color: black;
}

// 체크인 체크아웃이 선택되었을 때 그 사의 날짜들에 호버 혹은 클릭했을 시 스타일
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  border: none;
  color: black;

  .day-content {
    background-color: var(--selected-array);
  }
}

// 선택된 체크인 체크아웃 날짜에 대한 스타일
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: none;
  color: white;
}

// 블록된 날짜에 대한 스타일링
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #f3f4f6;
  border: none;
  color: #a3a8af;
  box-shadow: none;
}

// 선택될 범위에 대한 스타일링
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: black;
  background-color: var(--gray-400);
  border: none;
}

// 요일 표시 부분에 대한 스타일.
.CalendarMonth_caption {
  // margin-bottom: 10px;
}

.CalendarDay {
  position: relative;
  transform-style: preserve-3d;
  padding: 0 !important;
}

.CalendarDay__selected,
.CalendarDay__selected_end,
.CalendarDay__selected_start {
  .day {
    background: var(--primary);
    border-radius: 50%;
  }
}

.CalendarDay__selected:after,
.CalendarDay__selected_start:after {
  content: '가는날';
  position: absolute;
  color: var(--primary);
  text-align: center;
  width: 100%;
  display: block;
}

.CalendarDay__selected_end:after {
  content: '오는날';
  position: absolute;
  color: var(--primary);
  text-align: center;
  width: 100%;
  display: block;
}

.CalendarDay.CalendarDay__default.CalendarDay__selected_start.CalendarDay__selected {
  .day-wrapper {
    width: 50%;
    height: 30px;
    right: 0;
    background-color: var(--selected-array);
    position: fixed;
  }
}

.CalendarDay.CalendarDay__default.CalendarDay__selected_end.CalendarDay__selected {
  .day-wrapper {
    width: 50%;
    height: 30px;
    left: 0;
    background-color: var(--selected-array);
    position: fixed;
  }
}

.CalendarDay.CalendarDay__default.CalendarDay__selected_start.CalendarDay__selected_end.CalendarDay__selected {
  .day-wrapper {
    background-color: unset;
  }
}

.CalendarDay.CalendarDay__selected.CalendarDay__selected_start.CalendarDay__selected_end::after {
  content: '당일';
}

.DayPicker__withBorder {
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid var(--gray-200);
}

.dayoff-calendar {
  @media (min-width: 640px) {
    & {
      height: calc(100% - 115px)
    };
  }
  height: 100%;

  .DayPickerNavigation_button__verticalDefault {
    box-shadow: none;
  }

  .DayPickerNavigation_button__default {
    border: none;
  }

  .DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
    border: none;
  }

  .CalendarDay__default:hover {
    //background: unset;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: white;
    border: none;
    color: black;
  }

  .CalendarDay__highlighted_calendar {
    background: var(--selected-node) !important;
    border: none !important;
    color: white !important;
  }
}

.DayPicker_weekHeader__verticalScrollable {
  background: #f3f4f6;
  border: none;
  padding: 0 !important;
}

.DayPicker_weekHeader_ul {
  margin-top: 6px;
  display: flex;
  justify-content: space-evenly;
}

.text-day-red {
  color: #fa3c41 !important;
}

.CalendarDay__default .day {
  font-weight: bold;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
}
